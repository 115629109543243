<template>
  <div class="grid md:flex-row">
    <div class="text-left text-sm">
      {{ name }}
    </div>
    <div class="mt-2 flex flex-row items-center text-center">
      <div class="sr-only">Ціна</div>
      <div class="flex items-center text-sm text-gray-700">
        {{ price }}
        <span aria-hidden="true" class="ml-1 text-xs text-gray-400">x</span>
      </div>
      <div class="ml-1">
        <div class="sr-only">Кількість</div>
        <Counter :counter="count" @inc="inc" @dec="dec" />
      </div>
      <div class="">
        <span aria-hidden="true" class="ml-1 text-xs text-gray-500">=</span>
        <span class="sr-only">Сума</span>
        <span class="ml-0.5 text-gray-900">
          {{ (+price * count).toFixed(2) }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import Counter from '../Counter.vue';

defineProps<{ name: string; price: string; count: number }>();

const emit = defineEmits<{
  (e: 'inc'): void;
  (e: 'dec'): void;
}>();

const inc = () => {
  emit('inc');
};
const dec = () => {
  emit('dec');
};
</script>
