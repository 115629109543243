<template>
  <Modal :is-open="isVisible" @close="aborted">
    <template v-slot:header> Кошик </template>
    <div role="list" class="mt-4">
      <template v-for="item in items" :key="item.id">
        <TransitionRoot
          :show="shouldShow(item)"
          @after-leave="leave(item)"
          leave="transition-all duration-700 ease-out"
          leave-from="opacity-100 translate-0"
          leave-to="opacity-0 -translate-x-full"
        >
          <div
            class="card mt-3 grid select-none gap-4 rounded-md bg-gray-100 p-4 shadow-md"
            role="listitem"
          >
            <div class="w-full grid-cols-2" id="item-desc" tabindex="0">
              <CartItem
                :name="item.name"
                :count="item.count"
                :price="item.price"
                @inc="inc(item)"
                @dec="dec(item)"
              />
            </div>
            <button
              class="ml-1 self-baseline text-red-600 md:self-center"
              tabindex="0"
              aria-labelledby="label-delete-btn item-desc"
              @click="markForRemove(item)"
            >
              <span class="sr-only" id="label-delete-btn">Видалити</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </TransitionRoot>
      </template>
    </div>

    <div class="mt-3 text-right font-bold">Сума: {{ total }}</div>

    <template v-slot:footer>
      <button
        class="mr-2 mt-3 flex items-center justify-center rounded bg-mango-600 px-2.5 py-1.5 text-center text-white hover:bg-mango-500 disabled:cursor-not-allowed disabled:bg-mango-300"
        @click="orderItems"
        :disabled="!canOrder"
      >
        Замовити
      </button>

      <button
        class="mr-2 mt-3 flex items-center justify-center rounded bg-mango-600 px-2.5 py-1.5 text-center text-white hover:bg-mango-500"
        @click="aborted"
      >
        Повернутись до товарів
      </button>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { TransitionRoot } from '@headlessui/vue';
import { useStore } from '@nanostores/vue';
import { reactive, computed } from 'vue';
import {
  decreaseCount,
  getSelectedProductsList,
  increaseCount,
  type Product,
  removeSelectedProduct,
} from '../../store';
import Modal from './../Modal.vue';
import CartItem from './CartItem.vue';

defineProps<{ isVisible: boolean }>();
const emit = defineEmits<{
  (e: 'closed', items: readonly Product[]): void;
  (e: 'aborted'): void;
}>();

const markedForRemove = reactive<Record<string, boolean>>({});

const items = useStore(getSelectedProductsList);
const canOrder = computed(
  () => Array.isArray(items.value) && items.value.length > 0,
);

const total = computed(() => {
  return items.value
    .reduce((prev, item) => prev + (item?.count ?? 0) * +item.price, 0)
    .toFixed(2);
});

const shouldShow = (item: Product) => {
  return markedForRemove[item.id] !== true;
};

const markForRemove = (item: Product) => {
  markedForRemove[item.id] = true;
};

const orderItems = () => {
  emit('closed', items.value);
};

const aborted = () => {
  emit('aborted');
};

const incCount = (item: Product) => increaseCount(item);
const decCount = (item: Product) => decreaseCount(item);
const removeProduct = (item: Product) => removeSelectedProduct(item);

const inc = (item: Product) => {
  gtag('event', 'shop', { item_name: item.name, type: 'increaseCount' });
  incCount(item);
};
const dec = (item: Product) => {
  gtag('event', 'shop', { item_name: item.name, type: 'decreaseCount' });
  decCount(item);
};
const removeItem = (item: Product) => {
  gtag('event', 'shop', { item_name: item.name, type: 'removeProduct' });
  removeProduct(item);
};

const leave = (item: Product) => {
  if (markedForRemove[item.id] === true) {
    removeItem(item);
    markedForRemove[item.id] = false;
  }
};
</script>
<style scoped>
.card {
  grid-template-columns: 1fr 20px;
}
</style>
