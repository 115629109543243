<template>
  <div class="flex flex-row rounded border border-gray-500 font-mono">
    <div
      class="remove flex items-center justify-center rounded-l bg-gray-400 px-2 font-mono text-xs text-white transition-all hover:scale-125"
      role="button"
      @click="dec()"
    >
      -
    </div>
    <div
      class="w-8 bg-white px-1 font-mono text-sm text-gray-600"
      role="spinbutton"
      aria-valuemin="0"
      :aria-valuenow="counter"
      tabindex="0"
      @keyup.up="inc()"
      @keyup.down="dec()"
    >
      {{ counter }}
    </div>
    <div
      class="add flex items-center justify-center rounded-r bg-gray-400 px-2 font-mono text-xs text-white transition-all hover:scale-125"
      role="button"
      @click="inc()"
    >
      +
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{ counter: number }>();
const emit = defineEmits<{
  (e: 'inc'): void;
  (e: 'dec'): void;
}>();

const inc = () => {
  emit('inc');
};

const dec = () => {
  emit('dec');
};
</script>
