<template>
  <Modal :is-open="isVisible" @close="close">
    <template v-slot:header>Информация о заказе</template>

    <spinner v-if="status === 'loading'"></spinner>
    <success v-if="status === 'success'"></success>

    <form @submit="onSubmit" class="mt-3" v-if="status === 'ready'">
      <div class="active mt-2 flex flex-col">
        <label class="ml-2 text-sm text-gray-600" for="requestFio">
          Контактное лицо (ФИО):
        </label>
        <input
          v-model="model.requestFio"
          placeholder="Контактное лицо (ФИО)"
          class="rounded-md px-2"
          id="requestFio"
          type="text"
          name="fio"
          autocomplete="name"
        />
      </div>
      <div class="active mt-2 flex flex-col">
        <label class="ml-2 text-sm text-gray-600" for="requestPhone">
          Контактный телефон:
        </label>
        <input
          v-model="model.requestPhone"
          placeholder="Контактный телефон"
          class="rounded-md px-2"
          id="requestPhone"
          type="text"
          name="phone"
          autocomplete="tel"
        />
      </div>
      <textarea style="display: none" name="items" :value="items"></textarea>

      <button
        class="mr-2 mt-3 flex items-center justify-center rounded bg-mango-600 px-2.5 py-1.5 text-center text-white hover:bg-mango-500 disabled:cursor-not-allowed disabled:bg-mango-300"
        @click="clearCart()"
        :disabled="!isValidModel"
      >
        Оформить заказ
      </button>
    </form>
  </Modal>
</template>
<script setup lang="ts">
import { computed, ref, shallowReactive } from 'vue';
import { clearProducts, type Product } from '../../store';
import Modal from '../Modal.vue';
import Spinner from '../Spinner.vue';
import Success from '../Success.vue';

const emit = defineEmits<{ (e: 'closed'): void }>();
const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    data: Product[];
  }>(),
  {
    isVisible: () => false,
    data: () => [],
  },
);

const status = ref<'ready' | 'loading' | 'success'>('ready');
const model = shallowReactive({ requestFio: '', requestPhone: '' });
const items = computed(() => convertItemsData([...props.data]));

const isValidModel = computed(() => {
  return model.requestFio.length > 0 && model.requestPhone.length > 0;
});

const close = () => {
  emit('closed');
};

const clearCart = () => {
  clearProducts();
};

function convertItemsData(list: Product[]) {
  return list
    .map(
      e =>
        `${e.type} - ${e.category} - ${e.name} ` +
        `Цена: ${e.price}грн. x ${e.count}шт. ` +
        `Сумма: ${+e.price * (e?.count ?? 0)}грн.\n`,
    )
    .join('\n');
}

const clearForm = () => {
  model.requestFio = '';
  model.requestPhone = '';
};

const onSubmit = async (e: any) => {
  gtag('event', 'shop', { type: 'submitOrder' });

  e.preventDefault();
  e.stopPropagation();

  try {
    status.value = 'loading';
    const mailerUrl = import.meta.env.PUBLIC_MAILER_URL as string;

    const translation: Record<string, string> = {
      fio: 'Контактное лицо (ФИО)',
      phone: 'Контактный телефон',
      items: 'Заказ',
    };

    const data = new FormData(e.target);
    const text = [...data.keys()]
      .map(el => `${translation[el]}:${data.getAll(el).join('\n')}`)
      .join('\n');

    await fetch(mailerUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        subject: 'Интернет заказ',
        text,
      }),
    });
  } catch (error) {
    console.error(error);
  } finally {
    status.value = 'success';
    clearForm();

    setTimeout(() => {
      status.value = 'ready';
      close();
    }, 3000);
  }
};
</script>
