<template>
  <div
    class="relative"
    role="button"
    tabindex="0"
    @keyup.enter="clickOnCart"
    @keyup.space="clickOnCart"
    @click.prevent="clickOnCart"
  >
    <span class="sr-only">Корзина</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="mr-2 h-10 w-full text-mango-600"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
      />
    </svg>
    <div
      class="absolute bottom-0 right-0 flex h-5 w-5 items-center justify-center rounded-full bg-gray-400 text-xs"
    >
      <span>{{ count }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = withDefaults(defineProps<{ count?: number }>(), {
  count: () => 0,
});
const emit = defineEmits<{ (e: 'clicked'): void }>();

const clickOnCart = () => {
  if (props.count > 0) {
    emit('clicked');
  } else {
    window.location.href = '/products';
  }
};
</script>
