<template>
  <div class="cart-vue">
    <div>
      <OrderInfoModal
        :isVisible="isOrderInfoPopupVisible"
        :data="items"
        @closed="closedOrderInfoModal"
      />
    </div>
    <div>
      <CartViewModal
        :isVisible="isCartPopupVisible"
        @closed="showOrderInfoModal"
        @aborted="hideCartPopup"
      />
    </div>
    <CartButton @clicked="showCartModal" :count="selectedProductsCount" />
  </div>
</template>
<script setup lang="ts">
import { useStore } from '@nanostores/vue';
import { computed, ref } from 'vue';
import {
  getSelectedProducts,
  hideOrderInfoPopup,
  hidePopup,
  popups,
  type Product,
  showOrderInfoPopup,
  showPopup,
} from '../../store';
import CartButton from './CartButton.vue';
import CartViewModal from './CartViewModal.vue';
import OrderInfoModal from './OrderInfoModal.vue';

const items = ref<Product[]>([]);

const popupsState = useStore(popups);
const selectedProductsState = useStore(getSelectedProducts);

const isCartPopupVisible = computed(() => popupsState.value.showCartPopup);
const selectedProductsCount = computed(
  () => selectedProductsState.value.length,
);
const isOrderInfoPopupVisible = computed(
  () => popupsState.value.showOrderInfoPopup,
);

const hideCartPopup = () => hidePopup();
const showCartPopup = () => showPopup();
const closedOrderInfoModal = () => hideOrderInfoPopup();

const showCartModal = () => {
  gtag('event', 'shop', { type: 'openCart' });
  showCartPopup();
};

const showOrderInfoModal = (data: Product[]) => {
  gtag('event', 'shop', { type: 'orderInfo' });
  hideCartPopup();

  items.value = data;

  showOrderInfoPopup();
};
</script>
